@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

:root {
  --aria_blue: #3d8aff;
  --bg_white: #ffffff;
  --column_width: 60em;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: "Pangram", "Arial", "sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
}

/* p {
  font-size: 17pt;
} */

.app_div {
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.App {
  text-align: center;
}

.basic_input {
  border-radius: 4px;
  border: 1px solid grey;
  padding: 0.5em;
}

.basic_button {
  color: white;
  font-family: "Inter", "Arial", "sans-serif";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  background-color: var(--aria_blue);
  border-radius: 4px;
  border: none;
  box-shadow: 0px 4px 19px 2px rgba(0, 0, 0, 0.12);
  padding: 0.5em 1em;
  /* margin: 0.5em; */
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.basic_button:disabled {
  background-color: darkgrey;
  border-color: darkgrey;
}

.basic_button:disabled {
  background-color: grey;
}

.secondary_button {
  color: black;
  font-family: "Inter", "Arial", "sans-serif";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  background-color: white;
  border-radius: 4px;
  border: 5px solid var(--aria_blue);  
  /* border: none; */
  padding: 1em;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}
.secondary_button:disabled {  
  border: 5px solid darkgrey;
  color: darkgrey;
  cursor:not-allowed;
}
.nav_bar .secondary_button {
  border: none;
}

.card {
  background: var(--bg-white);
  border-radius: 12px;
  box-shadow: 0px 4px 19px 2px rgba(0, 0, 0, 0.12);
  padding: 2em 2.5em;
  /* margin: 0px auto; */
  display: block;
}

.dialog_box {
  box-sizing: border-box;
  margin: 5em auto;
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 1em;
  border: 1px solid black;
  padding: 3em;
  background-color: white;
}

.coffee_people {
  position: fixed;
  z-index: -10;
  right: 0;
  bottom: 0;
  max-width: 50vw;
}

.aria_blue {
  color: var(--aria_blue);
}

.wrapper {
  padding: 20px;
}

.survey {
  margin: 2rem;
  padding: 1rem;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.errorText {
  color: red;
}

.padded {
  padding: 1em;
}

.survey_tool_question {
  display: flex;
  flex-direction: column;
}

.response_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
}

.choice_text {
  cursor: pointer;
  padding: 0.8em;
  border: 0.5px solid grey;
  margin: 1em;
  text-align: center;
}

input[type="radio"]:checked + .choice_text {
  border: 5px solid purple;
}

/* .survey_progress_button {
  cursor: pointer;
  padding: 0.8em;
  color: white;
  border-radius: 0.5em;
}

.quit {
  background-color: darkred;
} */

.admin_home .user_table_headings {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-weight: bold;

}

.admin_home .survey_table_headings {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: left;
    font-weight: bold;
}

.admin_home .section_header {
    display: inline;
    font-size: 2em;
    font-weight: bolder;
    margin-right: 0.5em;
    margin-top: 4em;
}

.admin_home .usability_survey_records_table_headings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: left;
    font-weight: bold;
}
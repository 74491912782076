.registration_details .card {
  max-width: 30em;
  margin: 0 auto;
  display: grid;
  grid-template-rows: "repeat(4, auto)";
  row-gap: 0.5em;
}

/* .registration_details input {
    width: 100%;
} */

.registration_details .already_have_account {
  text-align: center;
  display: flex;
  justify-content: center;
}

.registration_details .link_button {
    display: inline;    
    color: var(--aria_blue);
    background: none;
    border: none;
    cursor: pointer;
}

.demographic_questionnaire {
    max-width: 50%;
    margin: 0px auto;
}
.demographic_questionnaire .card > div {
    margin-top: 2rem;
}

.demographic_questionnaire h3 {
    display: block;
    color: var(--aria_blue);
    font-family: 'Inter', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
}

.demographic_questionnaire ul {
    padding-left: 0;
}

.demographic_questionnaire label + label {    
    margin-left: 4rem;
}

.demographic_questionnaire li {
    list-style: none;    
}

.demographic_questionnaire input[type="text"] {
    border: none;
    border-bottom: 1px solid black;
}

.demographic_questionnaire textarea {
    width: 100%;
    min-height: 8rem;
}

.demographic_questionnaire .factors_considered_question{
    margin-bottom: 2em;
}
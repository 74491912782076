#about_page {
    margin: 2em auto;
    max-width: var(--column_width);
    position: relative;
}

#about_page .end_buttons {
    display: flex;
    flex-direction: row-reverse;
    
}
    
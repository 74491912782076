.home_page {
    background-image: url(../../img/family.png);    
    background-repeat: no-repeat;
    background-position: bottom right;    
}

#home_page .intro_text {
    margin-left: 2em;
    box-sizing: border-box;
    max-width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;    
}

#home_page h1 {
    font-size: 48px;
    color: var(--aria_blue);
    font-family: "Inter", "Arial", "sans-serif";
}
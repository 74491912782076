.grid_wrapper {
    display: grid;
    grid-template-columns: auto 1fr repeat(5, 1.5em);
    /* grid-auto-rows: minmax(100px, auto); */            
    column-gap: 1em;
    row-gap: 1em;            
    align-items: center;
}

.sentiment_label {
    writing-mode: sideways-lr;
    /* text-orientation: sideways; */
}

.sentiment_strongly_disagree,  input[value='strongly_disagree']{
    /* grid-column-start: 2; */
    grid-column-end: 4;
}

.sentiment_disagree, input[value='disagree'] {
    /* grid-column-start: 3; */
    grid-column-end: 5;
}

.sentiment_neutral, input[value='neutral'] {
    /* grid-column-start: 4; */
    grid-column-end: 6;
}

.sentiment_agree, input[value='agree'] {
    /* grid-column-start: 5; */
    grid-column-end: 7;
}

.sentiment_strongly_agree, input[value='strongly_agree'] {
    /* grid-column-start: 6; */
    grid-column-end: 8;
}

.question_category {
    grid-column-start: 1;
    writing-mode: sideways-lr;
}
/* .question_text {
    grid-column-start: 2;
} */
.survey_question {
    grid-column-start: 2;
    grid-column-end: 8;    
    display: grid;
    grid-template-columns: 1fr repeat(4, 1.5em);
    column-gap: 1em;
}

.category_trustworthiness {
    grid-row-start: 3;
    grid-row-end: 6;
}
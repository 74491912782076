.survey_tool {    
    background-image: url("../../img/app_builders.png");
    background-repeat: no-repeat;
    background-position: bottom left;    
}

.survey_tool .hero {
    width: var(--column_width);
    margin: 0 auto;
}

.survey_tool h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.survey_tool .question_text {
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 140%;    
}

.survey_tool .sub_text {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 140%;
}

.survey_tool .sentiment_labels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0em 1em;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 2em;
}

.survey_tool .sentiment_labels .center{
    justify-self: center;
}

.survey_tool .sentiment_labels .right {
    justify-self: end;
}

.survey_progress_buttons {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 2em;    
  }

  .survey_progress_buttons .previous {
      justify-self: left;
  }
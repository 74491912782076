.app_panel {
    display:grid;
    grid-template-columns: 1fr 1fr 2em auto 2em auto;
    grid-template-rows: auto auto auto auto;
    /* grid-template-areas: 
    "title          title       . firstRatingText   . secondRatingText"
    "title          title       . firstRating       . secondRating"    
    "description    description . firstStars        . secondStars"
    "appStore       playStore   . takeFirst         . takeSecond"; */
    max-width: 75vw;
    --button-height: 3em;
}

.app_panel .first {
    grid-column: 4/5;
}

.app_panel .second {
    grid-column: 6/7;
}

.rating_title {
    grid-row: 0/1;
}

.score_text {
    grid-row: 2/3;
}

.stars {
    grid-row: 3/4;
}

.app_panel .button {
    grid-row: 4/5;
    max-height: var(--button-height);
    font-size: 14px;
    line-height: 16px;
}

.app_panel .app_header {
    grid-column: 1/3;
    grid-row: 1/3;
    display: flex;
    align-items: center;
}

/* .app_panel .app_info {
    padding-right: 2em;
} */

.app_panel .app_icon {
    width: 5em;
    height: 5em;
    border: 2px solid #585858;
    border-radius: 0.5rem;
}

.app_panel .app_title {
    margin-left: 1em;
}

.app_panel .app_description {
    grid-column: 1/3;
    grid-row: 3/4;
    font-family: 'Inter', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.5px;
    color: #585858;
}
.app_panel .app_store_buttons {
    grid-row: 4/5;
    grid-column: 1/3;
    display: flex;
    gap: 2em;
}
.app_panel .app_button {
    display: block;
}

.app_panel .app_button img{
    height: var(--button-height);
}

.app_panel .appStore {
    grid-column: 1/2;
}

.app_panel .playStore {
    grid-column: 2/3;
}

/* .app_store_buttons img{
    height: 4em;
    margin: 0.5em;
} */

/* .ratings_overview {
    display: grid;
    grid-template-columns: 1fr 1fr;
} */

.star_text {
    background-color: grey;
    background-clip: text;
    color: transparent;
}
.user_home {
  display: grid;
  place-items: center;
}

.user_home .header {
  margin-bottom: 2em;
  text-align: center;
}

.user_home .app_panel {
    padding: 1em;
}

.user_home .app_panel:not(:first-child){
    border-top: 2px solid grey;
    margin-top: 1em;        
}
.system_usability_survey {
    max-width: var(--column_width);
    margin: 0px auto;
}

.system_usability_survey .usability_intro {
    font-family: "Pangram", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: var(--aria_blue);
}

.system_usability_survey h1 {
    font-family: 'Pangram', 'Arial', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
}

.system_usability_survey h1 + p {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
    color: #727272;
}

.system_usability_survey .button {
    
    text-align: right;
}
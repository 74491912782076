.rating_scale_input{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto auto;    
    column-gap: 1em;
    justify-items: center;    
    justify-content: center;
    margin: 2em 1em;    
}

.rating_scale_input .fancy_radio_button {
    width: 100%;
    height: 100%;
}

/* Hide the actual HTML radio buttons */
.rating_scale_input .choice_radio_button {    
    display: none;
  }

.rating_scale_input input[type="radio"] ~ label {
    display: grid;
    color: var(--aria_blue);        
    height: 3em;
    align-content: center;
    justify-content: center;
    border-radius: 0.5em;
    border: 2px solid grey;
    
}

.rating_scale_input input[type="radio"]:checked ~ label{
    color: white;
    background-color: var(--aria_blue);
    border: none;
}
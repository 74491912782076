.registration {
  background-image: url(../../img/doctor.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}

.registration .hero {
  display: block;
  flex-direction: column;
  padding: 0.5em;
  margin: 2em auto;  
}

.registration .information {
  max-width: var(--column_width);
  margin: 2em auto;
}

.registration .end_button {
  display: flex;
  flex-direction: row-reverse;
}

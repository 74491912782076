.identify_your_role {
  --card-radius: 28px;
  width: 100%;  
}

.identify_your_role .hero {
  max-width: var(--column_width);
  margin: 0 auto;
}

.identify_your_role .choice_wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  /* align-self: center; */
  column-gap: 4em;
  /* display: flex; */
  margin: 2em auto;
  max-width: var(--column_width);
  margin: 0 auto;
}

.identify_your_role .role_choice {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid grey; */
  border-radius: var(--card-radius);
  /* padding: 2em; */
  border: 2px solid blue;
}

/* Hide the ugly default radio buttons */
.identify_your_role .role_choice input[type="radio"] {
  display: none;
}

.identify_your_role .role_choice {
    padding: 0;
    box-shadow: 0px 4px 19px 2px rgba(0, 0, 0, 12);
    border-radius: var(--card-radius);
    /* clip-path: content-box; */
    border: 1em solid white;  
    cursor: pointer;
}

.role_choice.disabled {
  border: 1em solid grey;
}

.role_choice.selected {
    border: 1em solid var(--aria_blue);
}


.identify_your_role .role_image {
  border-radius: 12px;
  opacity: 0.6;
}

.identify_your_role .role_name {
  position: absolute;
  bottom: 0;
  border-radius: 12px;
  
  background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0));
  width: 100%;
  height: 5em;
  display: grid;
  text-align: center;
  align-content: flex-end;

  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: white;  
}

.identify_your_role .role_name_disabled {
  position: absolute;
  bottom: 0;
  border-radius: 12px;
  
  background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0));
  width: 100%;
  height: 100%;
  display: grid;
  text-align: center;
  align-content: flex-end;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: white;  
}

.identify_your_role .role_descriptions {
  background-color: rgba(124, 124, 124, 0.1);
  border-radius: 16px;
  padding: 1em;
  margin: 2em auto;
  max-width: var(--column_width);
}

.identify_your_role .button_panel {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

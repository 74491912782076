#password_reset {
    display: grid;    
    place-items: center;
}

#password_reset .card {
    display: grid;
    max-width: 50vw;    
    background-color:white;
}

#password_reset input {
    height: 2em;
    margin-bottom: 0.5em;
}
#change_password {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: grid;    
    place-items: center;
}

#change_password .card {
    display: grid;
    max-width: 50vw;    
    background-color:white;
}

#change_password input {
    height: 2em;
    margin-bottom: 0.5em;
}
.appreciation_message{    
    max-width: var(--column_width);
    margin: 2em auto;
}

.appreciation_message .faculty_logo {
    max-height: 10em;    
    display: block;
    margin: 0 auto;    
}

.appreciation_message button {
    display: block;
    margin: 0 auto;
}

.appreciation_message .nothing_more_text {
    font-style: italic;
}
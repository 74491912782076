#log_in_dialog {
  display: grid;
  align-items: center;
}

#log_in_dialog .link {
    color: var(--aria_blue);
    cursor: pointer;
}

#log_in_dialog .spacer {
    height: 4em;
}
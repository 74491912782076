.nav_bar {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 1em;
  color: white;
  padding: 2em;
  
}
.aria_logo {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: var(--aria_blue);
  cursor: pointer;
  border: none;
  background-color: inherit;

  justify-self: left;
}

.nav_bar a {
  color: white;
}

.usability_question {
    margin-bottom: 2em;
}
.usability_question .question_text {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: var(--aria_blue);
}

.usability_question .rating_scale_input {
    margin-bottom: 0.5em;
}

.usability_question .choice_labels {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
}
#information_letter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

#information_letter h1 {
    font-size: 48px;
}

#information_letter .information_text {
  overflow: auto;
  border: 1px solid black;
  padding: 2em;
}

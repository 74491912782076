.user_list_entry {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    /* grid-auto-columns: 100px; */
    margin: 0.5em 0;
    
}

.user_list_entry:nth-child(2n){
    background-color: lightgray;
}